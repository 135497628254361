import { HomeIcon, InformationCircleIcon, NewspaperIcon } from '@heroicons/react/outline';

export const links = [
  {
    name: 'Accueil',
    href: '/',
    icon: HomeIcon,
  },
  {
    name: 'Publications',
    href: '/publications/',
    icon: NewspaperIcon,
  },
  {
    name: 'À propos',
    href: '/a-propos/',
    icon: InformationCircleIcon,
  },
];
