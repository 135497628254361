import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import imageUrl from '../images/ndiemesow.png';

function Seo({ description, lang, meta, title, image, type }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const siteUrl = site.siteMetadata.siteUrl;
  const metaImage = {
    src: `${siteUrl}${image ? image.src : imageUrl}`,
    height: (image && image.height) || 1200,
    width: (image && image.width) || 630,
  };
  const socialTags = [
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: type || `website`,
    },
    {
      property: `og:image`,
      content: metaImage.src,
    },
    {
      name: `twitter:card`,
      content: `summary_large_image`,
    },
    {
      name: `twitter:creator`,
      content: site.siteMetadata.author,
    },
  ];

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`${site.siteMetadata.title} | %s`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
      ]
        .concat(socialTags)
        .concat(meta)}
    />
  );
}

Seo.defaultProps = {
  lang: `fr`,
  meta: [],
  description: ``,
  type: 'website',
  image: {
    src: imageUrl,
    height: 1200,
    width: 630,
  },
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }),
  type: PropTypes.oneOf(['website', 'article']),
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default Seo;
